import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { role, isKrismar } = useAuth();
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        {
          path: 'app',
          element:
            role === 'isAdmin' || role === 'isStudent' || role === 'isTeacher' || role === 'isASC' ? (
              <GeneralApp />
            ) : (
              <Navigate to="/404" replace />
            )
        },
        { path: 'manager', element: <GeneralManager /> },
        { path: 'book', element: <GeneralBook /> },
        { path: 'preschool', element: <GeneralPreschool /> },
        { path: 'manuals', element: <GeneralManuals /> },
        { path: 'printables', element: <GeneralPrintables /> },
        { path: 'training', element: <GeneralTeacherTrainingList /> },
        { path: 'new-school', element: <NewMexicanSchool /> },
        {
          path: 'bookcolombia',
          children: [
            { element: <Navigate to="/dashboard/bookcolombia/root" replace /> },
            { path: '', element: <GeneralColombia /> },
            { path: 'book/:grade/:trim/:subject', element: <GeneralColombiaIframe /> }
          ]
        },
        {
          path: 'recursoscolombia',
          children: [
            { element: <Navigate to="/dashboard/recursoscolombia/root" replace /> },
            { path: '', element: <GeneralResourcesColombia /> }
          ]
        },
        {
          path: 'bookSierra',
          children: [
            { element: <Navigate to="/dashboard/bookSierra/root" replace /> },
            { path: '', element: <GeneralColombiaSierra /> },
            { path: 'book/:link', element: <GeneralColombiaSierraIframe /> }
          ]
        },
        {
          path: 'mindware',
          children: [
            { element: <Navigate to="/dashboard/mindware/root" replace /> },
            { path: '', element: <GeneralMindware /> }
          ]
        },
        {
          path: 'interactivas',
          children: [
            {
              element: <Navigate to="/dashboard/interactivas/root" replace />
            },
            { path: '', element: isKrismar ? <GeneralInteractivasc /> : <Navigate to="/404" replace /> },
            { path: 'grade/:gradeId/:levelId', element: <Interactivasc /> },
            { path: 'studentList', element: <Interactivasc /> },
            { path: 'activity/:coverURL', element: <InteractivascActivity /> }
          ]
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <GeneralAvatar /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <GeneralAvatar /> }
          ]
        },
        {
          path: 'manager',
          children: [
            { element: <Navigate to="/dashboard/manager/home" replace /> },
            { path: 'home', element: <GeneralManager /> },
            { path: 'students', element: <StudentsManager /> },
            { path: 'teachers', element: <TeachersManager /> },
            { path: 'paymentSchool', element: <PaymentSchoolManager /> },
            { path: 'userStudent', element: <UserStudentManager /> }
          ]
        },
        {
          path: 'preschool',
          children: [
            { element: <Navigate to="/dashboard/preschool/home" replace /> },
            { path: 'home', element: <GeneralPreschool /> },
            { path: 'dialogues', element: <GeneralPreschoolDialogs /> },
            { path: 'songs', element: <GeneralPreschoolSongs /> },
            { path: 'videos', element: <GeneralPreschoolVideos /> },
            { path: 'audios', element: <GeneralPreschoolAudios /> },
            { path: 'documents', element: <GeneralPreschoolMindMaps /> },
            { path: 'mindMaps', element: <GeneralPreschoolMindMaps /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'trainingTec', element: <TrainingTec /> },
        { path: 'scheduletec', element: <SheduleTec /> },
        { path: 'docapp', element: <DocApp /> },
        { path: 'library', element: <Library /> },
        { path: 'libraryTeachers', element: <LibraryTeachers /> }
        // { path: 'kanban', element: <Kanban /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/" replace /> },
        { path: '', element: <LandingPage /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralInteractivasc = Loadable(lazy(() => import('../pages/dashboard/GeneralInteractivasc')));
// Manager
const GeneralManager = Loadable(lazy(() => import('../pages/dashboard/GeneralManager')));
const StudentsManager = Loadable(lazy(() => import('../pages/dashboard/GeneralStudentsManager')));
const TeachersManager = Loadable(lazy(() => import('../pages/dashboard/GeneralTeachersManager')));
const PaymentSchoolManager = Loadable(lazy(() => import('../pages/dashboard/GeneralPaymentSchoolManager')));
const UserStudentManager = Loadable(lazy(() => import('../pages/dashboard/GeneralUserStudentManager')));
// Bookg
const GeneralBook = Loadable(lazy(() => import('../pages/dashboard/GeneralBook')));
const GeneralColombia = Loadable(lazy(() => import('../pages/dashboard/GeneralBookColombia')));
const GeneralColombiaIframe = Loadable(lazy(() => import('../pages/dashboard/GeneralColombiaIframe')));
// Resources
const GeneralResourcesColombia = Loadable(lazy(() => import('../pages/dashboard/GeneralResourcesColombia')));
// Book la sierra
const GeneralColombiaSierra = Loadable(lazy(() => import('../pages/dashboard/GeneralBookColombiaSierra')));
const GeneralColombiaSierraIframe = Loadable(lazy(() => import('../pages/dashboard/GeneralColombiaSierraIframe')));
const GeneralMindware = Loadable(lazy(() => import('../pages/dashboard/GeneralMindware')));
const GeneralManuals = Loadable(lazy(() => import('../pages/dashboard/GeneralManuals')));
const GeneralPrintables = Loadable(lazy(() => import('../pages/dashboard/GeneralPrintables')));
// Training
const GeneralTeacherTrainingList = Loadable(lazy(() => import('../pages/dashboard/GeneralTeacherTrainingList')));
const GeneralPreschool = Loadable(lazy(() => import('../pages/dashboard/GeneralPreschool')));
// Preschool
const GeneralPreschoolDialogs = Loadable(lazy(() => import('../pages/dashboard/GeneralPreschoolDialogs')));
const GeneralPreschoolSongs = Loadable(lazy(() => import('../pages/dashboard/GeneralPreschoolSongs')));
const GeneralPreschoolVideos = Loadable(lazy(() => import('../pages/dashboard/GeneralPreschoolVideos')));
const GeneralPreschoolAudios = Loadable(lazy(() => import('../pages/dashboard/GeneralPreschoolAudios')));
const GeneralPreschoolMindMaps = Loadable(lazy(() => import('../pages/dashboard/GeneralPreschoolMindMaps')));
// Interactivas
const Interactivasc = Loadable(lazy(() => import('../pages/dashboard/Interactivasc')));
const InteractivascActivity = Loadable(lazy(() => import('../pages/dashboard/InteractivascActivity')));
// Extras
const GeneralAvatar = Loadable(lazy(() => import('../pages/dashboard/GeneralAvatar')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Library = Loadable(lazy(() => import('../pages/dashboard/GeneralLibraryList')));
const LibraryTeachers = Loadable(lazy(() => import('../pages/dashboard/GeneralTeacherLibrary')));
const TrainingTec = Loadable(lazy(() => import('../pages/dashboard/GeneralTrainingTec')));
const SheduleTec = Loadable(lazy(() => import('../pages/dashboard/GeneralSheduleTec')));
const DocApp = Loadable(lazy(() => import('../pages/dashboard/GeneralDocApp')));
const NewMexicanSchool = Loadable(lazy(() => import('../pages/dashboard/NewMexicanSchool')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
