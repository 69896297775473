import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from '../../utils/api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  products: []
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProduct(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getProduct } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function fetchProducts(academicYearId, schoolId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(`/school-products/school/${schoolId}?academicYearId=${academicYearId}`);
      dispatch(getProduct(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
