import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from '../../utils/api';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  subjects: [],
  post: null,
  recentPosts: [],
  studentLevels: [],
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getSubjectsSuccess(state, action) {
      state.isLoading = false;
      state.subjects = action.payload;
    },

    // GET POSTS
    getAvatar(state, action) {
      state.isLoading = false;
      state.avatar = action.payload;
    },

    setStudentLevels(state, action) {
      state.isLoading = false;
      state.studentLevels = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getSubjects(countryCode, academicLevelId, academicGradeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(
        `/academic-subjects/find?countryCode=${countryCode}&academicLevelId=${academicLevelId}&academicGradeId=${academicGradeId}`
      );
      dispatch(slice.actions.getSubjectsSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function changeAvatar(userId, avatar) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.put(`/users/update-avatar/${userId}/${avatar}`);
      dispatch(slice.actions.getAvatar(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function checkStudentLevels(personId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(`/students-v2/person/${personId}`);
      dispatch(slice.actions.setStudentLevels(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
