import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './api';
import { decodeToken, checkAuth, checkPermissions } from './helper';
// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getRole = (response) => {
  const isASC = checkPermissions(['ASC'], response);
  const isAdmin = checkPermissions(
    ['SUPER-ADMIN', 'ADMINISTRATION', 'ADMINISTRATIVE', 'EXECUTIVE', 'SCHOOL-OWNER', 'IT', 'SCHOOL-IT', 'DESIGN'],
    response
  );
  const isTeacher = checkPermissions(['TEACHER', 'DIRECTORIAL', 'COORDINATION'], response);
  const isStudent = checkPermissions(['STUDENT'], response);
  let res;
  if (isASC) {
    res = 'isASC';
  }
  if (isAdmin) {
    res = 'isAdmin';
  }
  if (isTeacher) {
    res = 'isTeacher';
  }
  if (isStudent) {
    res = 'isStudent';
  }
  return res;
};
const checkIsImex = (response) => {
  const isColombia = response.data.roles.some((e) => e.schoolId === 377);
  return isColombia;
};
const checkIsKrismar = async (response) => {
  if (response.data.krismar) {
    return true;
  }
  return false;
};
const checkIsColombia = async (response) => {
  try {
    const isColombia = response.data.roles.some((e) => e.schoolId === 137 || e.schoolId === 138);
    return isColombia;
  } catch (error) {
    return error;
  }
};
const checkIsColombiaSierra = async (response) => {
  try {
    const isColombia = response.data.roles.some((e) => e.schoolId === 81);
    return isColombia;
  } catch (error) {
    return error;
  }
};
const checkIsPreschoolFFE = async (response) => {
  try {
    const isPreschoolFFE = response.essentials?.some(
      (item) => item.toLowerCase().includes('fit for english') || item.toLowerCase().includes('preescolar')
    );
    console.log(`checkIsPreschoolFFE: ${isPreschoolFFE}`);
    return isPreschoolFFE;
  } catch (error) {
    return error;
  }
};
const checkIsPreschool = async (response) => {
  try {
    const isPreschool = response.essentials?.some((item) => item.toLowerCase().includes('preescolar'));
    console.log(`checkIsPreschool: ${isPreschool}`);
    return isPreschool;
  } catch (error) {
    return error;
  }
};
const setSession = (accessToken, reauth) => {
  if (reauth === true) {
    checkAuth(accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else if (accessToken != null) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export {
  isValidToken,
  setSession,
  verify,
  sign,
  decodeToken,
  getRole,
  checkIsKrismar,
  checkIsColombia,
  checkIsColombiaSierra,
  checkIsImex,
  checkIsPreschoolFFE,
  checkIsPreschool
};
