import { useLocation, Outlet, Link } from 'react-router-dom';
// material
import { Box, Container, Typography, Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// components
import moment from 'moment';
import '../../assets/footer.css';
import MainNavbar from './MainNavbar';

function SocialIcons() {
  return (
    <Grid container direction="row" alignItems="center" style={{ gap: '8px' }}>
      <a
        href="https://www.facebook.com/ASCeducation.mx"
        target="_blank"
        rel="noopener noreferrer"
        style={{ backgroundColor: '#1778f2', display: 'block', borderRadius: '60%', width: '11%', height: '43px' }}
      >
        <FacebookIcon style={{ fontSize: 30, color: 'white', margin: '5px' }} />
      </a>
      <a
        href="https://instagram.com/asc.education"
        target="_blank"
        rel="noopener noreferrer"
        style={{ backgroundColor: '#c13584', display: 'block', borderRadius: '60%', width: '11%', height: '43px' }}
      >
        <InstagramIcon style={{ fontSize: 30, color: 'white', margin: '5px' }} />
      </a>
      <a
        href="https://www.youtube.com/c/ASCEducaci%C3%B3nquetransforma/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ backgroundColor: '#ff0000', display: 'block', borderRadius: '60%', width: '11%', height: '43px' }}
      >
        <YouTubeIcon style={{ fontSize: 30, color: 'white', margin: '5px' }} />
      </a>
      <a
        href="https://www.linkedin.com/company/educacionquetransforma/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ backgroundColor: '#0d66c2', display: 'block', borderRadius: '60%', width: '11%', height: '43px' }}
      >
        <LinkedInIcon style={{ fontSize: 30, color: 'white', margin: '5px' }} />
      </a>
    </Grid>
  );
}

function CustomLink({ to, children, typoColor, typoVariant, ...props }) {
  return (
    <Link to={to} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'solid' }} {...props}>
      <Typography component={typoVariant} color={typoColor}>
        {children}
      </Typography>
    </Link>
  );
}

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const year = moment().year();
  return (
    <>
      {!isHome ? <></> : <MainNavbar />}
      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <></>
      ) : (
        <>
          _{' '}
          <Box
            sx={{
              width: '100%',
              height: '60%',
              backgroundColor: '#4b6db2',
              pt: '6rem',
              pl: '4rem',
              pr: '6rem'
            }}
          >
            <Container maxWidth="lg">
              <Grid container direction="column" alignItems="center">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <img
                      src="https://asc.education/wp-content/uploads/2023/10/LOGO-ASC.png"
                      width="170px"
                      alt="no found"
                      loading="lazy"
                    />
                    <SocialIcons />
                  </Grid>
                  <Grid item container direction="column" alignItems="center" xs={4}>
                    <Grid item xs={6}>
                      <Typography color="white" variant="h6">
                        Nuestros
                        <br />
                        Productos
                      </Typography>
                      <CustomLink to="#" children="En Forma" typoColor="#ccc" typoVariant="p" />
                      <CustomLink to="#" children="Fit" typoColor="#ccc" typoVariant="p" />
                      <CustomLink to="#" children="Mision" typoColor="#ccc" typoVariant="p" />
                      <CustomLink to="#" children="Essentials" typoColor="#ccc" typoVariant="p" />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="white" variant="h6">
                        Plataformas
                      </Typography>
                      <Typography color="white" variant="h6">
                        En Forma / Fit
                      </Typography>
                      <CustomLink
                        to="https://asc.education/app-asc/"
                        children="App de contenidos"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                      <CustomLink
                        to="https://plataforma.asc.education/"
                        children="Plataforma ASC"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                      <Typography color="white" variant="h6">
                        Misión
                      </Typography>
                      <CustomLink
                        to="https://alumnos.mision.education/"
                        children="Acceso a alumnos"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                      <CustomLink
                        to="https://docentes.mision.education/"
                        children="Acceso docentes"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" alignItems="center" xs={4}>
                    <Grid item xs={6}>
                      <Typography color="white" variant="h6">
                        Atención al
                        <br />
                        cliente
                      </Typography>
                      <CustomLink to="#" children="(+52) 800 841 77 42" typoColor="#ccc" typoVariant="p" />
                      <CustomLink
                        to="mailto:info@asc.education"
                        children="info@asc.education"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                      <CustomLink
                        to="https://api.whatsapp.com/send?phone=529982219984"
                        typoColor="#ccc"
                        typoVariant="p"
                      >
                        <WhatsAppIcon style={{ fontSize: 20, color: '#ccc', marginTop: '5px' }} /> 998 221 9984
                      </CustomLink>
                      <Typography color="white" variant="h6">
                        Soporte técnico
                      </Typography>
                      <CustomLink
                        to="mailto:soporte.tech@asc.education"
                        children="soporte.tech@asc.education"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomLink
                        to="https://blog.asc.education/"
                        children="Blog ASC"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                      <CustomLink
                        to="https://www.google.com/maps/place/ASC/@21.126099,-86.8350007,17z/data=!3m1!4b1!4m5!3m4!1s0x8f4c2bc53acdd00f:0x8623530ef38c3ff8!8m2!3d21.126099!4d-86.832812"
                        children="¿Dónde estamos?"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                      <CustomLink
                        to="https://asc.education/politica-de-privacidad/"
                        children="Política de Privacidad"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                      <CustomLink
                        to="https://colegios.asc.education/login.html"
                        children="Portal Colegios"
                        typoColor="#ccc"
                        typoVariant="p"
                      />
                      <CustomLink
                        to="https://api.whatsapp.com/send?phone=529982219984"
                        typoColor="white"
                        typoVariant="h6"
                      >
                        Ventas: <WhatsAppIcon style={{ fontSize: 20, color: '#ccc', marginTop: '5px' }} /> 998 221 9984
                      </CustomLink>
                      <CustomLink to="https://asc.education/isauro-blanco/" typoColor="white" typoVariant="h6">
                        Isauro Blanco
                      </CustomLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '2%',
              backgroundColor: '#38aae1',
              paddingTop: '5rem',
              paddingBottom: '5rem'
            }}
          >
            <Container maxWidth="lg">
              <Grid container direction="column" alignItems="center">
                <Typography color="white" variant="p">
                  © EDITORIAL MORSAN INTERNACIONAL, S.A. ® ASC Cancún, Quintana Roo. Todos los derechos reservados.
                </Typography>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </>
  );
}
