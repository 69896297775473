import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: 'https://asc-go-api.herokuapp.com/',
  // timeout: 1000,
  headers: {
    'x-app-id': '078705de-086e-4684-a05a-c195b57d1a50'
  }
});
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
