// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

// export const PATH_DOCS = {
// };
export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    newSchool: path(ROOTS_DASHBOARD, '/new-school'),
    app: path(ROOTS_DASHBOARD, '/app'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    book: path(ROOTS_DASHBOARD, '/book'),
    manuals: path(ROOTS_DASHBOARD, '/manuals'),
    printables: path(ROOTS_DASHBOARD, '/printables'),
    training: path(ROOTS_DASHBOARD, '/training')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  bookcolombia: {
    root: path(ROOTS_DASHBOARD, '/bookcolombia'),
    book: path(ROOTS_DASHBOARD, '/bookcolombia/book')
  },
  recursoscolombia: {
    root: path(ROOTS_DASHBOARD, '/recursoscolombia')
  },
  bookSierra: {
    root: path(ROOTS_DASHBOARD, '/bookSierra'),
    book: path(ROOTS_DASHBOARD, '/bookSierra/book')
  },
  mindware: {
    root: path(ROOTS_DASHBOARD, '/mindware')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  interactivas: {
    root: path(ROOTS_DASHBOARD, '/interactivas'),
    grade: path(ROOTS_DASHBOARD, '/interactivas/grade'),
    studentList: path(ROOTS_DASHBOARD, '/interactivas/studentList'),
    activity: path(ROOTS_DASHBOARD, '/interactivas/activity')
  },
  manager: {
    root: path(ROOTS_DASHBOARD, '/manager/home'),
    students: path(ROOTS_DASHBOARD, '/manager/students'),
    teachers: path(ROOTS_DASHBOARD, '/manager/teachers'),
    paymentSchool: path(ROOTS_DASHBOARD, '/manager/paymentSchool'),
    userStudent: path(ROOTS_DASHBOARD, '/manager/userStudent')
  },
  preschool: {
    root: path(ROOTS_DASHBOARD, '/preschool/home'),
    dialogs: path(ROOTS_DASHBOARD, '/preschool/dialogues'),
    songs: path(ROOTS_DASHBOARD, '/preschool/songs'),
    videos: path(ROOTS_DASHBOARD, '/preschool/videos'),
    audios: path(ROOTS_DASHBOARD, '/preschool/audios'),
    documents: path(ROOTS_DASHBOARD, '/preschool/documents'),
    mindMaps: path(ROOTS_DASHBOARD, '/preschool/mindMaps')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  trainingTec: path(ROOTS_DASHBOARD, '/trainingTec'),
  scheduletec: path(ROOTS_DASHBOARD, '/scheduletec'),
  docapp: path(ROOTS_DASHBOARD, '/docapp'),
  library: path(ROOTS_DASHBOARD, '/library'),
  libraryTeachers: path(ROOTS_DASHBOARD, '/libraryTeachers')
};
